/* Tweaks from default minicss theme. */

/**
 * Fix render bug where <pre><code> blocks indent the first line.
 * <pre><code> is valid HTML; see https://stackoverflow.com/questions/4611591/
 * for more information.
 */
pre code {
  padding: 0;
}

// Ensure smallest headers are still at least as big as base text.
h6 {
  font-size: 1em;
}
